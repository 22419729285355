import React from 'react'

const Banner = props => {
    return (<div className="banner">
                <div className="sub-title">
                    {props.label}
                </div>
            </div>)
}
                    


export default Banner